<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Transport settings
            <template slot="actions">
                <mercur-button :to="addTransportSetting" v-if="isAllowedTo('addSupplierTransportSetting')" class="btn btn-yellow btn-raised">
                    <i class="fas fa-plus"></i>
                    <span>Add new transport setting</span>
                </mercur-button>
            </template>
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
            ref="grid"
            v-if="isAllowedTo('getAllSupplierTransportSettings')"
        />
    </mercur-card>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'

export default {
    name: 'OrderSubmissionsTransportSettingsOverview',
    components: { DataTable, GridHeader },
    data () {
        return {
            options: {
                columns: {
                    'Supplier': {
                        field: 'supplierName',
                        sortable: true,
                    },
                    'Status': {
                        field: 'settingsStatus',
                        sortable: true,
                        statusChip: true,
                    },
                    'Transport': {
                        field: 'transportSettings.transportFormat',
                        valueFormatter: ({ data }) => {
                            return `${data.transportSettings.transportClass} (${data.transportSettings.transportFormat})`
                        },
                    },
                    'Location': {
                        field: 'facilities',
                        valueFormatter: ({ data }) => {
                            if (!data.facilities) {
                                return
                            }
                            return data.facilities.map(e => e.facilityName)
                        },
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                    },
                },
                actions: [
                    {
                        text: 'View',
                        icon: 'fas fa-edit',
                        disabled: () => {
                            return !this.isAllowedTo('updateSupplierTransportSetting')
                        },
                        to: ({ data }) => {
                            return {
                                name: 'OrderSubmissionsTransportSettingsEdit',
                                params: {
                                    transportSettingId: data.transportSettingId,
                                },
                            }
                        },
                    },
                ],
                quickSearchColumns: ['supplierName', 'locationName'],
                sortModel: [
                    {
                        colId: 'supplierName',
                        sort: 'asc',
                    },
                ],
                pagination: false,
                paginationPageSize: 9999,
                cacheBlockSize: 9999,
            },
            url: CONFIG.API.ROUTES.ORDER_SUBMISSION.TRANSPORT_SETTINGS.OVERVIEW,
            filters: {
                search: '',
            },
            addTransportSetting: {
                name: 'OrderSubmissionsTransportSettingsNew',
            },
        }
    },
}
</script>
